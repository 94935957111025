import React from 'react';
import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import FaqSection from './../components/FaqSection';
import SEO from '../components/SEO';

function FaqPage(props) {
  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />
      <PageHeadSection title="Frequently Asked Questions" />
      <FaqSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title=""
        subtitle=""
        data-aos="fade-up"
      />
    </Layout>
  );
}

export default FaqPage;

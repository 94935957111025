import React, { useState } from 'react';
import './FaqItem.scss';

function FaqItem(props) {
  const [expanded, setExpanded] = useState(false);

  const aosProps = {};
  for (const key of Object.keys(props)) {
    if (key.indexOf('data-aos') === 0) {
      aosProps[key] = props[key];
    }
  }

  return (
    <article
      className="FaqItem"
      onClick={() => setExpanded(!expanded)}
      {...aosProps}
    >
      <div className="title is-spaced is-4">
        <span className="FaqItem__icon icon is-size-5 has-text-primary">
          <i
            className={
              'fas' +
              (expanded ? ' fa-minus' : '') +
              (!expanded ? ' fa-plus' : '')
            }
          ></i>
        </span>
        {props.question}
      </div>

      {expanded && <div className="subtitle">{props.answer}</div>}
    </article>
  );
}

export default FaqItem;

import React from 'react';
import FaqItem from './FaqItem';

function Faq(props) {
  return (
    <>
      {props.items.map((item, index) => (
        <FaqItem
          question={item.question}
          answer={item.answer}
          key={index}
          data-aos="fade-up"
          data-aos-delay={400 + 100 * index}
        />
      ))}
    </>
  );
}

export default Faq;
